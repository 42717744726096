import CKEditor from '@ckeditor/ckeditor5-vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import VueTypes from 'vue-types'
import { mapState } from 'vuex'
import animateScrollTo from '@/utils/animateScrollTo'

export default {
  name: 'ck-editor-field',
  components: {
    ckeditor: CKEditor.component,
  },
  props: {
    value: VueTypes.oneOfType([ VueTypes.string, null ]).def(null),
    disabled: VueTypes.bool.def(false),
    autofocus: VueTypes.bool.def(false),
    focusId: VueTypes.oneOfType([ VueTypes.string, null ]).def(null),
    editorConfig: VueTypes.any.def(null),
    placeholder: VueTypes.string,
  },
  data () {
    return {
      editor: ClassicEditor,
      disableNextInput: false,
      editorInstance: null,
    }
  },
  mounted () {
    this.$root.$on('set-focus', this.listenerForFocus)
  },
  beforeDestroy () {
    this.$root.$off('set-focus', this.listenerForFocus)
  },
  computed: {
    ...mapState({
      isPrivatePage: state => state.app.authorized,
    }),
    editorConfigCustom () {
      if (this.editorConfig) {
        return this.editorConfig
      }
      const toolbarItems = ['heading', 'bold', 'italic', 'bulletedList', 'numberedList', 'blockQuote']
      if (!this.isMobileLx) {
        toolbarItems.push('undo')
        toolbarItems.push('redo')
      }
      let viewportTopOffset = 50
      const width = Math.max(
        document.documentElement?.clientWidth || 0,
        window.innerWidth
      )
      if (this.isPrivatePage) {
        if (width > 599) {
          const pageContainer = document.getElementById('pageContainer')
          if (pageContainer) {
            viewportTopOffset = Number.parseInt(getComputedStyle(pageContainer).paddingTop)
          }
        }
      } else {
        if (width > 800) {
          viewportTopOffset = 70
        }
      }
      return {
        toolbar: {
          items: toolbarItems,
          viewportTopOffset,
        },
        placeholder: this.placeholder ? this.placeholder : null,
        removePlugins: ['Link', 'MediaEmbed', 'EasyImage', 'ImageCaption', 'ImageUpload'],
        language: 'en',
      }
    },
  },
  methods: {
    listenerForFocus (focusId) {
      if (focusId && focusId === this.focusId) {
        this.focus()
        const target = this.$refs?.editor?.$_instance?.ui?.view?.element
        if (target) {
          animateScrollTo(target)
        }
      }
    },
    focus () {
      this.$refs.editor.$_instance.editing.view.focus()
    },
    onReady (v) {
      this.disableNextInput = true
      v.setData(this.value)
      this.editorInstance = v
      if (this.autofocus) {
        this.focus()
      }
    },
    onInput (v) {
      if (this.disableNextInput) {
        this.disableNextInput = false
        return
      }
      this.$emit(
        'input',
        v.replace(new RegExp('&nbsp;', 'g'), ' ').replace(new RegExp('<br>', 'g'), '<br />')
      )
    },
    setData (v) {
      this.editorInstance.setData(v)
    }
  },
}
